import { Form } from "react-bootstrap";
import styled from "styled-components";

export const StyledRequiredLabel = styled(Form.Label)`
  &::after {
    content: "*";
    color: red;
    font-weight: bold;
    margin-left: 0.2rem;
  }
`;
