import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #fff;
  &:hover {
    color: #fff;
  }
`;

export const StyledBrandLink = styled(StyledLink)`
  color: #000;
  &:hover {
    color: #000;
  }
`;
