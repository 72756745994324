import { Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { StyledButtonCol } from "../pages/SearchPage.styled";
import { useFormik } from "formik";
import { object, string, date } from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { StyledErrorText } from "./ErrorText.styled";
import { StyledRequiredLabel } from "./CheckRevenueForm.styled";

const revenueSchema = object().shape({
  associateId: string().required("Xin nhập mã định danh"),
  startDate: date().required(),
  endDate: date().required(),
});
export const CheckRevenueForm = ({ onSubmit }: any) => {
  const formik = useFormik({
    initialValues: {
      associateId: "",
      startDate: new Date(),
      endDate: new Date(),
    },
    onSubmit,
    validationSchema: revenueSchema,
  });
  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs={12} md={4}>
          <Form.Group className="mb-3">
            <StyledRequiredLabel>
              <b>Mã định danh:</b>
            </StyledRequiredLabel>

            <Form.Control
              name="associateId"
              value={formik.values.associateId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Nhập mã định danh"
            />

            {formik.errors.associateId && formik.touched.associateId && (
              <StyledErrorText>{formik.errors.associateId}</StyledErrorText>
            )}
          </Form.Group>
        </Col>

        <Col xs={12} md={3}>
          <Form.Group className="mb-3">
            <StyledRequiredLabel>
              <b>Ngày bắt đầu:</b>
            </StyledRequiredLabel>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="form-control"
              selected={formik.values.startDate}
              onChange={(date: Date) => formik.setFieldValue("startDate", date)}
              selectsStart
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              name="startDate"
            />
          </Form.Group>
        </Col>

        <Col xs={12} md={3}>
          <Form.Group>
            <StyledRequiredLabel>
              <b>Ngày kết thúc:</b>
            </StyledRequiredLabel>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="form-control"
              selected={formik.values.endDate}
              onChange={(date: Date) => formik.setFieldValue("endDate", date)}
              selectsEnd
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              minDate={formik.values.startDate}
            />
          </Form.Group>
        </Col>

        <StyledButtonCol xs={12} md={2}>
          <Form.Group className="mb-3">
            <Form.Label>placeholder</Form.Label>
            <Button type="submit" variant="success">
              Kiểm tra
            </Button>
          </Form.Group>
        </StyledButtonCol>
      </Row>
    </Form>
  );
};
