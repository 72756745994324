import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

interface IAuthContext {
  token: ITokenResponse | undefined;
  removeToken: () => void;
  saveToken: (tokenResponse: ITokenResponse) => void;
}

export const AuthContext = createContext<IAuthContext>({
  token: undefined,
  saveToken: () => null,
  removeToken: () => null,
});

export interface ITokenResponse {
  token: string;
  expiration: string;
}
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<ITokenResponse | undefined>(() =>
    getPersistedToken()
  );
  console.log("auth context", token);
  const saveToken = useCallback((tokenResponse: ITokenResponse) => {
    console.log("saveToken", tokenResponse);
    localStorage.setItem("token", JSON.stringify(tokenResponse));
    setToken(tokenResponse);
  }, []);
  const removeToken = useCallback(() => {
    localStorage.removeItem("token");
    setToken(undefined);
  }, []);

  function getPersistedToken() {
    const persistedToken = localStorage.getItem("token");

    if (persistedToken) {
      return JSON.parse(persistedToken);
    }

    return undefined;
  }
  // useEffect(() => {
  //   if (token) saveToken(token);
  // }, [token]);
  return (
    <AuthContext.Provider value={{ token, saveToken, removeToken }}>
      {children}
    </AuthContext.Provider>
  );
};
