import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { StyledErrorText } from "../components/ErrorText.styled";
import { AuthContext, ITokenResponse } from "../contexts/AuthContext";

const loginSchema = object().shape({
  username: string().required(),
  password: string().required(),
});
export const LoginPage = () => {
  const { token, saveToken } = useContext(AuthContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      const response = await fetch("/auth/login", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        saveToken(data);
        navigate("/admin/upload");
      } else {
        setError("Login Failed");
      }
    },
  });

  useEffect(() => {
    // if logged in then navigate to admin upload page
    if (token) {
      navigate("/admin/upload");
    }
  }, []);
  return (
    <Container>
      <Col md={{ span: 6, offset: 3 }}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>User Name:</Form.Label>
            <Form.Control
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              placeholder="Enter username"
            />
            {formik.errors.username && formik.touched.username && (
              <StyledErrorText>{formik.errors.username}</StyledErrorText>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password: </Form.Label>
            <Form.Control
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              placeholder="Password"
            />
            {formik.errors.password && formik.touched.password && (
              <StyledErrorText>{formik.errors.password}</StyledErrorText>
            )}
          </Form.Group>
          <Button variant="primary" type="submit">
            Login
          </Button>
        </Form>
        {error && <StyledErrorText>{error}</StyledErrorText>}
      </Col>
    </Container>
  );
};
