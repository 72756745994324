import { Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import { AuthContextProvider } from "./contexts/AuthContext";
import { LoginPage } from "./pages/LoginPage";
import { SearchPage } from "./pages/SearchPage";
import { UploadFilePage } from "./pages/UploadFilePage";

function App() {
  return (
    <AuthContextProvider>
      <NavBar></NavBar>
      <Routes>
        <Route path="/" element={<SearchPage />} />
        <Route path="/login" element={<LoginPage></LoginPage>}></Route>
        <Route path="/admin/upload" element={<UploadFilePage />} />
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
