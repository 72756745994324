import { Col } from "react-bootstrap";
import styled from "styled-components";

export const StyledButtonCol = styled(Col)`
  margin-bottom: 0.8rem;
  .form-label {
    width: 100%;
    visibility: hidden;
  }
  .btn {
    width: 100%;
  }
  @media (max-width: 576px) {
    margin-top: -12px;
  }
  @media (min-width: 768px) {
    .btn {
      width: auto;
    }
  }
`;
