import { useContext } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { AuthContext } from "../contexts/AuthContext";
import { StyledBrandLink, StyledLink } from "./NavBar.styled";

function NavBar() {
  const { token } = useContext(AuthContext);
  return (
    <Navbar bg="light" expand="lg" className="mb-4">
      <Container fluid>
        <Navbar.Brand>
          <StyledBrandLink to="/">Kiểm tra doanh thu</StyledBrandLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>
          <Button>
            {token ? (
              <StyledLink to="admin/upload">Upload</StyledLink>
            ) : (
              <StyledLink to="/login">Đăng nhập</StyledLink>
            )}
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
