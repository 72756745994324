import { Col, Container, Form, Row, Table } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { IRevenueRequest, IRevenueResponse } from "../types/revenueTypes";
import moment from "moment";
import { numberWithCommas } from "../utils/numberFormat";
import { orderBy } from "lodash";
import { CheckRevenueForm } from "../components/CheckRevenueForm";

export const SearchPage = () => {
  const [revenueData, setRevenueData] = useState<IRevenueResponse>();
  const customerRevenues = orderBy(
    revenueData?.customerRevenues,
    [(r) => r.revenue],
    "desc"
  );
  const revenueCommissions = revenueData?.revenueCommissions;

  const handleRevenueRequest = (values: IRevenueRequest) => {
    const { associateId, startDate, endDate } = values;
    fetch(
      `/revenue/${associateId}?startDate=${startDate.toLocaleDateString()}&endDate=${endDate.toLocaleDateString()}`
    )
      .then((response) => response.json())
      .then(setRevenueData);
  };

  return (
    <Container>
      <Row>
        <CheckRevenueForm onSubmit={handleRevenueRequest}></CheckRevenueForm>
      </Row>

      <Row>
        <Col lg={{ span: 6, offset: 3 }}>
          <Table bordered hover>
            <thead>
              <tr>
                <th className="table-danger">Tổng doanh thu</th>
                <th className="table-success">Tổng hoa hồng</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{numberWithCommas(revenueData?.totalRevenue ?? 0)}</td>
                <td>
                  {numberWithCommas(
                    Math.trunc(revenueData?.totalCommission ?? 0)
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg="6">
          <Form.Label>
            <b>Doanh thu theo ngày:</b>
          </Form.Label>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Ngày</th>
                <th>Doanh thu</th>
                <th>Hoa hồng</th>
              </tr>
            </thead>
            <tbody>
              {revenueCommissions?.map((rc, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    {moment(rc.date, "YYYY/MM/DD")
                      .format("DD/MM/YYYY")
                      .toString()}
                  </td>
                  <td>{numberWithCommas(rc.revenue)}</td>
                  <td>{numberWithCommas(Math.trunc(rc.commission))}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs="12" lg="6">
          <Form.Label>
            <b>Doanh thu theo khách:</b>
          </Form.Label>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Khách</th>
                <th>Tên</th>
                <th>Doanh thu</th>
              </tr>
            </thead>
            <tbody>
              {customerRevenues?.map((r, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{r.customerNumber}</td>
                  <td>{r.customerName}</td>
                  <td>{numberWithCommas(r.revenue)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
